import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { NavigationItem } from './models/navigation.model';
import { HelpersService } from './services/helpers/helpers.service';
import { NavigationService } from './services/navigation/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {


  public navbarItems: NavigationItem[];

  public constructor(
    private meta: Meta,
    private navigation: NavigationService,
    private helpers: HelpersService,
  ) {

    this.setup();
    this.init();

  }

  ngAfterViewInit(): void {
    this.hideSplashScreen();
  }

  private async init(): Promise<void> {

    const mainNavigation = await this.navigation.getMainNavigation();
    // console.log('AppComponent.mainNavigation', mainNavigation);
    this.navbarItems = mainNavigation.items;
  }
  
  private setup(): void {
    // this.disableSearchIndexOnSomeEnvironments();
  }
  
  // NOTE(stepanic): moved to index.html
  // private disableSearchIndexOnSomeEnvironments(): void {
  //   const hostname = window.location.hostname;    
  //   let isAllowedToIndex = false;
    
  //   environment.enableSearchIndexAtHostnames.forEach(host => {
  //     if (hostname === host) {
  //       isAllowedToIndex = true;
  //     }
  //   });  
    
  //   if (isAllowedToIndex) {
  //     this.meta.addTag({ name: 'robots', content: 'index, follow' });
  //   } else {
  //     this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' }); 
  //   }
  // }

  /**
   * Default hide splash screen after constant time, but every page should manually hide splash screen before.
   * This is just a protection to avoid application stacked in the state with the splash screen if some component/page do not hide it manually.
   */
  private hideSplashScreen(): void {
    setTimeout(() => {
      this.helpers.hideSplashScreen();
    }, 1000);
  }
}
