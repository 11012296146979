<div class="footer-wrapper bg-gray-50 border-t">

  <footer class="flex flex-col items-center justify-content text-center p-8 pt-16 pb-24">
    <div class="font-semibold">
        Projekt je sufinancirala Europska unija iz Kohezijskog fonda
    </div>
    <div class="flex items-center justify-content">
      <img src="https://ik.imagekit.io/aglomeracijapitomaca/eu-flag.svg" alt="" class="w-20 h-15 m-8 mt-4">
    </div>
    <div class="w-full lg:w-center lg:mr-6 text-center">
      Sva prava pridržana ©  Copyright by <strong>Virkom</strong> d.o.o. 2020.
    </div>
    <div class="font-light text-xs mt-4">
      Odricanje od odgovornosti: Sadržaj ove internetske stranice isključivo je odgovornost Virkom d.o.o. i ne odražava nužno gledište Europske unije
    </div>
  </footer>

</div>
