<div class="contact-page-wrapper max-w-none prose prose-red lg:prose-lg">

  <cms-content-status [status]="status"></cms-content-status>

  <div class="-mt-10 -mb-4 border-b border-gray-200">
    <h2>{{ title }}</h2>
  </div>

  <div class="flex flex-wrap overflow-hidden">

    <div class="flex flex-col items-center justify-center w-full overflow-hidden ">
      <div class="content prose prose-red lg:prose-lg w-full border border-gray-200 p-4 mt-8" >
        <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>
      </div>

    </div>


  </div>

</div>
