<div class="navbar-wrapper" >

  <nav class="flex flex-wrap items-center justify-between px-4">
    <a *ngIf="titleImage" [routerLink]="[titleLink]" class="m-4 ml-0" >
      <img class="max-h-16" [src]="titleImage" >
    </a>
    <div class="flex flex-grow lg:ml-0 lg:mr-0" >
      <a class="text-xl text-gray-900 font-semibold font-heading overflow-ellipsis" style="max-width: calc(100vw - 155px)" [routerLink]="[titleLink]">
        <ng-container *ngIf="!titleStart" >{{ title }}</ng-container>
        <ng-container *ngIf="titleStart" ><span class="font-normal" >{{ titleStart }}</span> {{ titleEnd }}</ng-container>
      </a>
    </div>
    <div class="block flex-shrink-0 lg:hidden flex-nowrap">
      <button *ngIf="items?.length"
              class="navbar-burger flex items-center py-2 px-3 rounded text-white focus:outline-none bg-gray-400"
              [class.bg-blue-600]="isOpenMenu"
              (click)="isOpenMenu = !isOpenMenu">
        <svg *ngIf="!isOpenMenu" class="text-white h-5 w-4" viewBox="2 0 24 18" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 15.8889C0 16.6866 0.64669 17.3333 1.44442 17.3333H14.5556C15.3533 17.3333 16 16.6866 16 15.8889V15.8889C16 15.0912 15.3533 14.4445 14.5556 14.4445H1.44442C0.64669 14.4445 0 15.0912 0 15.8889V15.8889ZM0 8.6667C0 9.46445 0.646705 10.1112 1.44446 10.1112H24.5555C25.3533 10.1112 26 9.46445 26 8.6667V8.6667C26 7.86895 25.3533 7.22224 24.5555 7.22224H1.44446C0.646705 7.22224 0 7.86895 0 8.6667V8.6667ZM1.44439 0C0.646674 0 0 0.646674 0 1.44439V1.44439C0 2.2421 0.646675 2.88878 1.44439 2.88878H24.5556C25.3533 2.88878 26 2.2421 26 1.44439V1.44439C26 0.646674 25.3533 0 24.5556 0H1.44439Z" fill="white"/>
        </svg>
        <svg *ngIf="isOpenMenu" class="text-white h-5 w-4" viewBox="-2 -1 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 1.71214L15.2879 0L8.5 6.78786L1.71214 0L0 1.71214L6.78786 8.5L0 15.2879L1.71214 17L8.5 10.2121L15.2879 17L17 15.2879L10.2121 8.5L17 1.71214Z" fill="white"/>
        </svg>
      </button>
    </div>

    <div *ngIf="mainImage" class="hidden 2xl:flex flex-grow w-auto items-center justify-center">
      <a [href]="mainImageLink" target="_blank" >
        <img class="max-h-18 max-w-screen-sm" [src]="mainImage" >
      </a>
    </div>

    <div *ngIf="items?.length" class="navbar-menu pb-4 -mt-4 lg:pb-0 lg:mt-0 lg:flex lg:flex-grow lg:items-center w-full lg:w-auto"
         [class.hidden]="!isOpenMenu">
      <div class="lg:ml-auto" routerLinkActive="active-link">

        <ng-container *ngFor="let item of items" >
          <a [routerLink]="[item.link]"
             [ngClass]="item.isActive || activeLink === item.link ? 'py-3 px-5 mt-4 lg:mt-0 leading-none text-white text-right bg-blue-600 hover:bg-blue-700 font-semibold rounded shadow' : 'mt-4 lg:mt-0 text-gray-900 hover:text-blue-600'"
             class="block lg:inline-block mr-0 lg:mr-5">{{ item.title }}</a>
        </ng-container>

      </div>
    </div>

    <div *ngIf="mainImage" class="flex 2xl:hidden flex-grow w-full items-center justify-center -mx-4 border-t border-gray-200">
      <a [href]="mainImageLink" target="_blank" >
        <img class="max-h-16 mt-0 max-w-full" [src]="mainImage" >
      </a>
    </div>
  </nav>
</div>
