<div class="layout-wrapper">

  <div class="flex flex-wrap overflow-hidden">

    <!-- HEADER -->
    <div class="w-full overflow-hidden fixed z-50 bg-white">
      <sss-header>
        <sss-navbar [title]="navbar.title"
                [titleImage]="navbar.titleImage"
                [titleLink]="navbar.titleLink"
                [activeLink]="navbar.activeLink"
                [mainImageLink]="navbar.mainImageLink"
                [mainImage]="navbar.mainImage"
                [items]="navbar.items"
                >
        </sss-navbar>
      </sss-header>
    </div>

    <!-- MAIN -->
    <div class="w-full overflow-hidden pt-36 mt-4 2xl:pt-24 2xl:-mt-2">
      <main class="container mx-auto py-4">
        <section class="">
          <ng-content></ng-content>
        </section>
      </main>
    </div>

    <!-- FOOTER -->
    <div class="w-full overflow-hidden">
      <sss-footer></sss-footer>
    </div>

  </div>


</div>
