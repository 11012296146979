// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCG-r7gVfOsS1IQf7pifh82hXJmfbanEKU",
    authDomain: "aglomeracija-pitomaca.firebaseapp.com",
    databaseURL: "https://aglomeracija-pitomaca.firebaseio.com",
    projectId: "aglomeracija-pitomaca",
    storageBucket: "aglomeracija-pitomaca.appspot.com",
    messagingSenderId: "270551238960",
    appId: "1:270551238960:web:b3d28d565dc7d29d9d60f5",
    measurementId: "G-5PNM81XQQ0"
  },
  // NOTE: implicit STATIC app are at port 1668 and hostname endsWith .netlify.app
  static: {
    hosts: [
      'aglomeracija-pitomaca.com.hr' // @Netlify
    ]
  },
  // NOTE: implicit DYNAMIC app is at ports 4200 and 1864 and hostname endsWith .web.app
  dynamic: {
    hosts: [
      // NOTE: explicit hostname endsWith .web.app is redundant but only defined as placeholder to be as example
      'aglomeracija-pitomaca.web.app'           // @Firebase Hosting
    ]
  },
  // enableSearchIndexAtHostnames: [
  //   'www.aglomeracija-pitomaca.com.hr', // @Netlify'  
  //   'aglomeracija-pitomaca.com.hr', // @Netlify'
  //   // 'localhost'  
  //   // All other staging, master, etc. environments are disabled by default
  // ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
