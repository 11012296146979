import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { FlamelinkModule, FLContentModule, FLNavigationModule, FLStorageModule } from 'ng-flamelink';
import { environment } from '../environments/environment';
import { HomePageComponent } from './home-page/home-page.component';
import { SharedModule } from './shared/shared.module';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { MarkdownModule } from 'ngx-markdown';
import { SecurityContext } from '@angular/core';
import { UiModule } from '@scanshop/sss-ui';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { EmptyPageComponent } from './empty-page/empty-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { GalleryModule, GALLERY_CONFIG } from 'ng-gallery';
import { LightboxModule, LIGHTBOX_CONFIG } from 'ng-gallery/lightbox';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    AboutPageComponent,
    EmptyPageComponent,
    ContactPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    ScullyLibModule.forRoot({
      useTransferState: true,
      alwaysMonitor: false,
      manualIdle: true,
    }),

    FlamelinkModule.initialize({
      dbType: 'cf', // Cloud Firestore or 'rtdb' for Real-Time Database
      firebaseApp: environment.firebaseConfig
    }),
    FLContentModule,
    FLStorageModule,
    FLNavigationModule,
    GalleryModule,
    LightboxModule,
    // NOTE: inside of FlamelinkModule.initialize() will be initialized Firebase app, and it is not required to init twice
    //AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,

    MarkdownModule.forRoot({
      // NOTE: sanitize by default is better, uncomment this only if you know what are you doing :))
      // simple HTML <b><div>, etc. are supported inside of the Markdown by default with sanitizer, but e.g. <script> is not!
      // sanitize: SecurityContext.NONE
    }),

    UiModule,

  ],
  providers: [
    {
      provide: GALLERY_CONFIG,
      useValue: {
        dots: true,
        imageSize: 'contain',
        // imageSize: 'cover',
      }
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: true,
        panelClass: 'fullscreen',
        hasBackdrop: true,
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
