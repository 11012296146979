import { Component, OnInit } from '@angular/core';
import { IdleMonitorService } from '@scullyio/ng-lib';
import { ContactPage } from '../models/contact.page.model';
import { PagesContentService } from '../services/pages/pages-content.service';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

  constructor(
    private ims: IdleMonitorService,
    private content: PagesContentService,
  ) { }

  private contact: ContactPage;

  public status: string;
  public title: string;
  public markdownContent: string;

  public onReadyMarkdownContent(): void {
    // console.log('contact.onReadyMarkdownContent');
    if (this.contact) {
      // console.log('contact.fireManualMyAppReadyEvent');
      this.ims.fireManualMyAppReadyEvent();
    }
  }

  public async ngOnInit(): Promise<void> {

    const contact = await this.content.getContact();
    this.contact = contact;

    this.title = contact.title;
    this.status = contact.status;
    this.markdownContent = contact.content;

    console.log('contact', contact);
  }

}
