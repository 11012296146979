<div class="about-page-wrapper max-w-none prose lg:prose-lg" >

  <cms-content-status [status]="status"></cms-content-status>

  <div class="-mt-10 -mb-4 border-b border-gray-200">
    <h2>{{ title }}</h2>
  </div>

  <div class="flex flex-wrap overflow-hidden">

    <div class="flex flex-col items-center justify-center w-full overflow-hidden">
      <div class="content prose prose-blue lg:prose-lg" *ngIf="markdownContent" >

        <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>

        <div class="flex flex-wrap overflow-hidden">

          <div class="w-full overflow-hidden md:w-2/3">
            <markdown [data]="markdownContentNumbers" (ready)="onReadyMarkdownContent()"></markdown>
          </div>

          <div class="w-full hidden overflow-hidden md:w-1/3 md:flex md:justify-center col-auto pl-8">
            <img src="https://ik.imagekit.io/aglomeracijapitomaca/logo-transparent-background.svg" class="w-full">
          </div>

        </div>

        <markdown [data]="markdownContentArea" (ready)="onReadyMarkdownContent()"></markdown>
      </div>
    </div>

  </div>

</div>

