import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sss-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  @Input()
  navbarItems: { title: string, link: string }[];

  public navbar = {
    title: 'Aglomeracija Pitomača',
    titleImage: 'https://ik.imagekit.io/aglomeracijapitomaca/tr:h-96/icon-transparent.png',
    titleLink: '/',
    activeLink: '/',
    mainImageLink: 'https://strukturnifondovi.hr/hr',
    mainImage: 'https://ik.imagekit.io/aglomeracijapitomaca/tr:w-1280,q-90/eu-fondovi-vidljivost-clean.png',
    items: [],
  }

  constructor() {

  }

  ngOnInit(): void {
    this.navbar.items = this.navbarItems;
  }

}
