<div class="home-page-wrapper prose lg:prose-lg max-w-none prose-blue" >

  <cms-content-status [status]="status"></cms-content-status>

  <div class="-mt-10 -mb-4 border-b border-gray-200">
    <h2>{{ title }}</h2>
  </div>

  <div class="flex flex-wrap overflow-hidden pb-2">

    <div class="w-full overflow-hidden md:w-2/3 md:pr-4">
      <div class="content prose lg:prose-lg" >
        <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>
      </div>

      <div class="flex flex-row items-center justify-center flex-wrap -mx-2 overflow-hidden logos-wrapper">

        <div class="px-2 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/5">
          <a href="https://virkom.hr" target="_blank" >
            <img src="https://ik.imagekit.io/aglomeracijapitomaca/tr:h-160/virkom-logo.jpg" style="height: 80px; width: 120px; margin: 0">
          </a>
        </div>

        <div class="px-2 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/5">
          <a href="https://vodakom.hr" target="_blank" >
            <img src="https://ik.imagekit.io/aglomeracijapitomaca/tr:h-120/vodakom-logo.png" style="height: 60px; width: 139px; margin: 0">
          </a>
        </div>

        <div class="px-2 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/5">
          <a href="https://pitomaca.hr" target="_blank" >
            <img src="https://ik.imagekit.io/aglomeracijapitomaca/tr:h-120/opcina-pitomaca-logo-v.png" style="height: 60px; width: 128px; margin: 0">
          </a>
        </div>

        <div class="px-2 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/5">
          <a href="https://mzoe.gov.hr" target="_blank" >
            <img src="https://ik.imagekit.io/aglomeracijapitomaca/tr:h-90/mgor-logo-transparent.png" style="height: 45px; width: 164.8px; margin: 20px 0">
          </a>
        </div>

        <div class="px-2 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/5">
          <a href="https://voda.hr" target="_blank" >
            <img src="https://ik.imagekit.io/aglomeracijapitomaca/tr:h-160/hrvatske-vode-logo.png" style="height: 80px; width: 57.5px; margin: 0">
          </a>
        </div>



      </div>
    </div>

    <div class="w-full overflow-hidden flex justify-center mt-10 p-4 bg-gray-50 border border-gray-200 md:w-1/3">
      <img class="w-auto max-h-96" src="https://ik.imagekit.io/aglomeracijapitomaca/logo-transparent-background.png?updatedAt=1607911057046&tr=h-768,fo-auto">
    </div>

  </div>

</div>

